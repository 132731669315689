import Input from '../../fields/input';
import {
  DeepMap,
  FieldError,
  UseFormRegister,
  UseFormGetValues,
  Controller,
  Control,
} from 'react-hook-form';
import { CtaButton, LinkButton, PaymentInputValues } from './payment-form';
import { COUNTRIES } from '../../../util/countries';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { CardElement } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import Spinner from '../../spinner';

export const EMAIL_REGEX =
  // eslint-disable-next-line no-control-regex
  /(?:[\d!#$%&'*+/=?^_`a-z{|}~-]+(?:\.[\d!#$%&'*+/=?^_`a-z{|}~-]+)*|"(?:[\u0001-\u0008\u000B\u000C\u000E-\u001F!\u0023-\u005B\u005D-\u007F]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])*")@(?:(?:[\da-z](?:[\da-z-]*[\da-z])?\.)+[\da-z](?:[\da-z-]*[\da-z])?|\[(?:(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d{1,2}|[\da-z-]*[\da-z]:(?:[\u0001-\u0008\u000B\u000C\u000E-\u001F\u0021-\u007F]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])+)])/;

type Props = {
  register: UseFormRegister<PaymentInputValues>;
  errors: DeepMap<PaymentInputValues, FieldError>;
  getValues: UseFormGetValues<PaymentInputValues>;
  control: Control<PaymentInputValues>;
  goBack: () => void;
  isSubmitting: boolean;
  isCapturingPayment: boolean;
};

export const PaymentStep: React.FC<Props> = (props) => {
  return (
    <>
      <PersonalInfo>
        <span>{props.getValues('name')}</span>
        <span>
          {props.getValues('zipCode')} {props.getValues('city')}
        </span>
        <span>{COUNTRIES[props.getValues('country')]}</span>
      </PersonalInfo>
      <LinkButton onClick={props.goBack}>Edit</LinkButton>
      <Separator />
      <EmailInput
        {...props.register('email', {
          required: 'Please enter your email',
          pattern: {
            value: EMAIL_REGEX,
            message: 'Invalid email address',
          },
        })}
        errors={props.errors}
        label="Your email address"
      />
      <Controller
        control={props.control}
        name="creditCard"
        rules={{
          validate: ((event: StripeCardElementChangeEvent | undefined) => {
            // show Stripe's internal error message if it has one
            if (event?.error?.message) {
              return event.error.message;
            }

            // show required text but only if validating during a submission
            if (props.isSubmitting && !event?.complete) {
              return 'Please enter your credit card details';
            }
            // credit card is complete during submission
            return true;
          }) as any,
        }}
        render={({ field: { onChange, onBlur } }) => (
          <CCInput
            label="Credit card"
            name="creditCard"
            errors={props.errors}
            Component={
              <CardElement
                options={{
                  hidePostalCode: true,
                  style: {
                    base: {
                      fontSize: '16px',
                      lineHeight: '1.5rem',
                    },

                    invalid: {
                      color: '#cc0044',
                    },
                  },
                }}
                onChange={onChange}
                onBlur={onBlur}
              />
            }
          />
        )}
      />
      <CtaButton variant="solid" color="primary" disabled={props.isCapturingPayment}>
        {props.isCapturingPayment ? <Spinner size={24} /> : 'Pay and download now'}
      </CtaButton>
    </>
  );
};

const PersonalInfo = styled.div`
  grid-column: 1/5;
  line-height: 2.4rem;
  > span {
    display: block;
  }
`;

const EmailInput = styled(Input)`
  grid-column: 1/-1;
`;

const CCInput = styled(Input)`
  grid-column: 1/-1;
`;

const Separator = styled.div`
  height: 0.2rem;
  background: #d4dae4;
  grid-column: 1/-1;
`;
