import { useActor } from '@xstate/react';
import { sentenceSplit, suppressions } from 'cldr-segmentation';
import md5 from 'md5';
import { assign, createMachine, interpret, send } from 'xstate';

import { apiClient } from '../api/api-client';
import { track } from '../tracking/ga';

const REFETCH_DELAY = 2_000;

// TODO: Remove V2 from name when old typings are deleted
export type Sentence = {
  text: string;
  hash: string;
  done: boolean;
  suggestions: HubbleSuggestion[];
  hideSuggestions?: boolean;
};

// TODO: Remove V2 from name when old typings are deleted
export type HubbleSuggestion = {
  id: string;
  category: string;
  replacement: string;
  explanation: string;
  suppressionCategory: string;
  groupNumber: number;
  start: number;
  end: number;
};

type PreviewContext = {
  plainText?: string;
  sessionId?: string;
  sentences: Sentence[];
  sentenceBuffer: Sentence[];
  nextBlockingSentence?: Sentence;
  selectedSuggestionId?: string;
};

type TextReceivedEvent = { type: 'INITIALIZE_PREVIEW_TEXT'; text: string; sessionId: string };
type FetchSuggestionsEvent = {
  type: 'FETCH_SUGGESTIONS';
  data: { sentenceHashes: string[] };
};
// an event that is fired automatically, shouldn't be used directly
type SuggestionFetchDoneEvent = {
  type: 'done.invoke.fetchSuggestions';
  data: {
    completed: boolean;
    liveSessionId: string;
    status: 'IN_PROGRESS' | 'SUCCESS';
    sentences: Sentence[];
  };
};
type SelectSuggestionEvent = { type: 'SELECT_SUGGESTION'; suggestionId: string };
type SelectPreviousSuggestionEvent = { type: 'SELECT_PREVIOUS_SUGGESTION' };
type SelectNextSuggestionEvent = { type: 'SELECT_NEXT_SUGGESTION' };
type ClearContext = { type: 'PREVIEW_CLEAR' };

type PreviewEvent =
  | TextReceivedEvent
  | FetchSuggestionsEvent
  | SelectNextSuggestionEvent
  | SelectSuggestionEvent
  | SelectPreviousSuggestionEvent
  | ClearContext;

type PreviewState =
  | { value: 'idle'; context: PreviewContext }
  | { value: 'submittingSentences'; context: PreviewContext }
  | { value: 'fetchingSuggestions'; context: PreviewContext }
  | { value: 'allSuggestionsFetched'; context: PreviewContext };

/**
 * index is the existingIndex from the list, so every suggestion id has added there sentences index
 */
function serializeSuggestions(suggestions: HubbleSuggestion[], index: number) {
  return suggestions.map((suggestion) => {
    return {
      ...suggestion,
      id: `${suggestion.id}_${index}`,
    };
  });
}

const initialContext = {
  plainText: undefined,
  sessionId: undefined,
  sentences: [],
  sentenceBuffer: [],
  nextBlockingSentence: undefined,
  selectedSuggestionId: undefined,
};

/**
 * The machine for the preview text and suggestion handling.
 * - builds sentences from plain review text
 * - submits sentences for suggestions
 * - continuously fetches suggestion results
 * - manages selected suggestion
 */
const previewMachine = createMachine<PreviewContext, PreviewEvent, PreviewState>(
  {
    id: 'preview',
    strict: true,
    initial: 'idle',
    context: initialContext,
    on: {
      PREVIEW_CLEAR: { target: '.idle', actions: 'clearContext' },
    },
    states: {
      idle: {
        on: {
          INITIALIZE_PREVIEW_TEXT: {
            target: 'submittingSentences',
            actions: ['assignInitialContext', 'buildSentencesToContext'],
          },
        },
      },
      submittingSentences: {
        invoke: {
          src: 'submitSentences',
          onDone: 'sentencesSubmitted',
        },
      },
      sentencesSubmitted: {
        // this state has two parallel child states. one that is concerned with fetching suggestions, and one that handles selected suggestion
        type: 'parallel',
        states: {
          suggestionFetching: {
            initial: 'fetchingSuggestions',
            states: {
              fetchingSuggestions: {
                invoke: {
                  src: 'fetchSuggestions',
                  onDone: [
                    {
                      // go to 'done' state when all suggestions have been fetch
                      cond: 'allSuggestionsFetched',
                      target: 'done',
                      actions: ['assignDoneSuggestions', 'ensureSelectedSuggestionId'],
                    },
                    {
                      // retry fetching suggestions as long as we still have incomplete sentences
                      actions: [
                        'assignDoneSuggestions',
                        'ensureSelectedSuggestionId',
                        'sendFetchSuggestionsEvent',
                      ],
                    },
                  ],
                },
                on: {
                  // re-enters self state to trigger suggestion fetching
                  FETCH_SUGGESTIONS: 'fetchingSuggestions',
                },
              },
              done: {
                entry: () => track('event', 'document', 'DOCUMENT_PREVIEW_DONE'),
                type: 'final',
              },
            },
          },
          suggestionSelection: {
            on: {
              SELECT_PREVIOUS_SUGGESTION: { actions: 'selectPreviousSuggestion' },
              SELECT_NEXT_SUGGESTION: { actions: 'selectNextSuggestion' },
              SELECT_SUGGESTION: { actions: 'selectSuggestion' },
            },
          },
        },
      },
    },
  },
  {
    actions: {
      clearContext: assign<PreviewContext, ClearContext>(initialContext) as any,
      assignInitialContext: assign<PreviewContext, TextReceivedEvent>({
        plainText: (_, event) => event.text,
        sessionId: (_, event) => event.sessionId,
      }) as any,
      // uses cldr-segmentation do create sentences from plain text preview
      buildSentencesToContext: assign<PreviewContext, TextReceivedEvent>((_, event) => {
        const sentences = sentenceSplit(event.text, suppressions.en).map((sentence) => ({
          text: sentence,
          hash: md5(sentence),
          done: false,
          suggestions: [],
        }));
        return {
          sentences,
        };
      }) as any,
      // finds any done suggestions from the fetch result and assigns them to context
      assignDoneSuggestions: assign<PreviewContext, SuggestionFetchDoneEvent>({
        sentences: (context, event) => {
          // it's important that the suggestions are assigned without reordering the array of sentences, as that would move the sentences around
          const nextSentences = [...context.sentences];
          const doneSentences = event.data.sentences.filter(({ done }) => done);
          let firstUndoneSentenceIndex = null;

          for (const [existingIndex, existingSentence] of context.sentences.entries()) {
            //FIXME: this if statement is a hack and should be remove when a better solution has been made (clickup task PPP-1300)
            if (existingIndex === 0) {
              nextSentences[existingIndex] = {
                ...nextSentences[existingIndex],
                suggestions: [],
                done: true,
              };
              continue;
            }
            // /FIXME: end of the hacky code block
            for (const doneSentence of doneSentences) {
              if (doneSentence.hash === existingSentence.hash) {
                nextSentences[existingIndex] = {
                  ...nextSentences[existingIndex],
                  suggestions: serializeSuggestions(doneSentence.suggestions, existingIndex),
                  done: true,
                };
              }
            }
            const nextSentence = nextSentences[existingIndex];

            if (firstUndoneSentenceIndex === null && !nextSentence.done) {
              firstUndoneSentenceIndex = existingIndex;
            }
            if (
              nextSentence.done &&
              firstUndoneSentenceIndex !== null &&
              firstUndoneSentenceIndex < existingIndex
            ) {
              nextSentences[existingIndex] = {
                ...nextSentence,
                hideSuggestions: true,
              };
            } else if (nextSentence.done && firstUndoneSentenceIndex === null) {
              nextSentences[existingIndex] = {
                ...nextSentence,
                hideSuggestions: false,
              };
            }
          }
          return nextSentences;
        },
      }) as any,
      // sends a FETCH_SUGGESTIONS event with any sentences that are still incomplete
      sendFetchSuggestionsEvent: send<
        PreviewContext,
        SuggestionFetchDoneEvent,
        FetchSuggestionsEvent
      >(
        (_, event) => ({
          type: 'FETCH_SUGGESTIONS',
          data: {
            sentenceHashes: event.data.sentences
              .filter(({ done }) => !done)
              .map(({ hash }) => hash),
          },
        }),
        { delay: REFETCH_DELAY },
      ) as any,
      // ensures a suggestion is always selected. handy when the first suggestions come in. if a suggestion is already selected it does nothing
      ensureSelectedSuggestionId: assign<PreviewContext, SuggestionFetchDoneEvent>({
        selectedSuggestionId: (context) =>
          context.selectedSuggestionId ||
          context.sentences
            .filter(({ hideSuggestions }) => !hideSuggestions)
            .flatMap(({ suggestions }) => suggestions)[0]?.id,
      }) as any,
      // selects previous suggestion if not already on the first suggestion
      selectPreviousSuggestion: assign<PreviewContext, SelectPreviousSuggestionEvent>({
        selectedSuggestionId: (context) => {
          const suggestionsList = context.sentences
            .filter(({ hideSuggestions }) => !hideSuggestions)
            .flatMap(({ suggestions }) => suggestions);
          if (suggestionsList.length === 0) {
            return;
          }
          const currentSelectedSuggestion = suggestionsList.find(
            ({ id }) => id === context.selectedSuggestionId,
          );
          if (!currentSelectedSuggestion) {
            // fallback to just selected the first suggestion of no current selection is found. Shouldn't happen.
            return suggestionsList[0].id;
          }
          const currentSelectedIndex = suggestionsList.indexOf(currentSelectedSuggestion);
          return currentSelectedIndex < 1
            ? currentSelectedSuggestion.id // keep current selection if the first one is currently selected
            : suggestionsList[currentSelectedIndex - 1].id;
        },
      }) as any,
      // selects next suggestion if not already on the last suggestion
      selectNextSuggestion: assign<PreviewContext, SelectNextSuggestionEvent>({
        selectedSuggestionId: (context) => {
          const suggestionsList = context.sentences
            .filter(({ hideSuggestions }) => !hideSuggestions)
            .flatMap(({ suggestions }) => suggestions);
          if (suggestionsList.length === 0) {
            return;
          }
          const currentSelectedSuggestion = suggestionsList.find(
            ({ id }) => id === context.selectedSuggestionId,
          );

          if (!currentSelectedSuggestion) {
            // fallback to just selected the first suggestion of no current selection is found. Shouldn't happen.
            return suggestionsList[0].id;
          }
          const currentSelectedIndex = suggestionsList.indexOf(currentSelectedSuggestion);
          return currentSelectedIndex === suggestionsList.length - 1
            ? currentSelectedSuggestion.id // keep current selection if the last one is currently selected
            : suggestionsList[currentSelectedIndex + 1].id;
        },
      }) as any,
      selectSuggestion: assign<PreviewContext, SelectSuggestionEvent>({
        selectedSuggestionId: (_, event) => event.suggestionId,
      }) as any,
    },
    services: {
      // initial submit of sentences to get suggestions
      submitSentences: async (context) => {
        const submitResponse = await apiClient.put<{
          data: { liveSessionId: string; sentenceHashes: string[] };
        }>(
          `/api/v1/live-session/${context.sessionId}/sentences`,
          context.sentences.map(({ text }) => text),
        );
        return { sentenceHashes: submitResponse.data.sentenceHashes };
      },
      // fetch suggestions for sentence hashes in event
      fetchSuggestions: (async (
        context: PreviewContext,
        event: FetchSuggestionsEvent,
      ): Promise<any> => {
        const statusResponse = await apiClient.post<{
          data: {
            completed: boolean;
            liveSessionId: string;
            status: 'IN_PROGRESS' | 'SUCCESS';
            sentences: Sentence[];
          };
        }>(`/api/v1/live-session/${context.sessionId}/status`, event.data.sentenceHashes);
        return statusResponse.data;
      }) as any,
    },
    guards: {
      allSuggestionsFetched: ((_: PreviewContext, event: SuggestionFetchDoneEvent) =>
        event.data.completed) as any,
    },
  },
);

const previewService = interpret(previewMachine, {
  devTools: true,
});

export const startPreviewService = () => {
  previewService.start();
};

export const usePreviewMachine = () => useActor(previewService);
